import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/layout/layout";
import Markdown from "react-markdown";
import * as styles from "./article.module.css"
import BasicLayout from "../../components/BasicLayout";
import LatestNews from "../../components/latest-news.js"
import DownloadFileList from '../../components/DownloadFileList';
import StyledButton from '../../components/StyledButton';

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      strapiId
      title
      content
      publishedAt
      category {
        name
      }
      image {
        childImageSharp {
          fixed(width: 1500, height: 1000) {
            src
          }
        }
      }
      failai {
        name
        url
      }
    }
    allStrapiArticle(limit: 5 sort: {fields: publishedAt, order: DESC}) {
      edges {
        node {
          strapiId
          slug
          title
          publishedAt(formatString: "LL", locale: "lt")
          category {
            name
          }
          image {
            childImageSharp {
              fixed(width: 1500, height: 1000) {
                src
              }
            }
          }
        }
      }
    }
  }
`;

const Article = ({ data }) => {
  const article = data.strapiArticle;
  const articles = data.allStrapiArticle;
  const seo = {
    metaTitle: article && article.title ? article.title : '',
    shareImage: article && article.image ? article.image : '',
    article: true,
  };

  return <div>
    {article && <Layout seo={seo}>

      <BasicLayout
        left={
          <>
            <div>
              <div>
                <h1 className={styles.heading}>{article.title}</h1>
                <button className={styles.newsCategory}>{article?.category?.name}</button>

                <div className={styles.imageContainer}>
                {article?.image?.childImageSharp?.fixed &&
                  <Img className={styles.image}
                    fixed={article.image.childImageSharp.fixed}
                    imgStyle={{ position: "static" }} />
                }
                </div>

                <div className={styles.text}>
                  <Markdown source={article.content} escapeHtml={false} />
                  <DownloadFileList documents={article?.failai} />
                </div>

              </div>
              <StyledButton to="/naujienos" name="Grįžti" />

            </div>
          </>}
        right={
          <>
            <LatestNews articles={articles} />
          </>
        } />

    </Layout>}
  </div>

};

export default Article;
