
import React from "react"
import * as styles from "./StyledButton.module.css"
import { Link } from "gatsby";
import { MdArrowForward } from "@react-icons/all-files/md/MdArrowForward";

export default function StyledButton({to, name }) {

    return (
        <Link to={to} className={styles.allArticles}>
            <div>{name}</div>
            <MdArrowForward size={30} />
        </Link>
    )
}