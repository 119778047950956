import React from 'react'
import * as styles from './latest-news.module.css'
import { Link } from "gatsby";

export default function LatestNews({ articles }) {
    return (
        <div className={styles.container}>
            <h3 style={{ paddingTop: '20px', marginTop: '0' }}>Paskutinės naujienos</h3>
            {articles.edges.map((article, i) => (
                <Link to={`/naujiena/${article.node.slug}`}>
                    <div className={styles.latestNews} key={i}>
                        <div className={styles.newsDate}>
                            {article.node.publishedAt}
                        </div>
                        <div className={styles.newsName}>
                            {article.node.title}
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    )
}